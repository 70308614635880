import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryStateService } from './query-state.service';
import {SummaryModel} from "../../../model/dtos/client/client-summary.dto";
import {SmtpConnectModel} from "../../../model/smtp-connect.model";
import {ClientFilterModel} from "../../../model";
import {SmtpParametersDto} from "../../../model/dtos/smtp-parameters.dto";
import {FlowstateMappingDto} from "../../../model/dtos/client/flowstate-mapping.dto";

@Injectable()
export class SettingsService {

  private settingsUrl = 'settings';

  constructor(private http: HttpClient, private queryStateService: QueryStateService) {
  }

  public async disconnectFromTeamLeader(clientFilterModel: ClientFilterModel) {
    try {
      return await this.http.put(`${this.settingsUrl}/teamleader/disconnect`, clientFilterModel).toPromise();
    } catch (error) {
      console.error("Error disconnecting from TeamLeader:", error);
      throw error;
    }
  }

  public async disconnectFromOctopus(clientFilterModel: ClientFilterModel) {
    try {
      return await this.http.put(`${this.settingsUrl}/octopus/disconnect`, clientFilterModel).toPromise();
    } catch (error) {
      console.error("Error disconnecting from Octopus:", error);
      throw error;
    }
  }

  public async disconnectFromYuki() {
    const queryParams = this.queryStateService.addClientParameters({}) as any;
    return await this.http.put(`${this.settingsUrl}/yuki/disconnect`, queryParams).toPromise();
  }

  async subscribeSummary(summarySettings: SummaryModel): Promise<SummaryModel> {
    try {
      const paramsToSend = this.queryStateService.addClientParameters({}) as any;
      return await this.http.post<SummaryModel>(`${this.settingsUrl}/summary/subscribe`, summarySettings, { params: paramsToSend }).toPromise();
    } catch (error) {
      console.error("Error subscribing to summary:", error);
      throw error;
    }
  }

  async unsubscribeSummary() {
    try {
      const clientFilter = this.queryStateService.addClientParameters({}) as any;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return await this.http.post(`${this.settingsUrl}/summary/unsubscribe`, clientFilter, { headers }).toPromise();
    } catch (error) {
      console.error("Error unsubscribing from summary:", error);
      throw error;
    }
  }

  async getSummarySettings(): Promise<SummaryModel> {
    try {
      const paramsToSend = this.queryStateService.addClientParameters({}) as any;
      return await this.http.get<SummaryModel>(`${this.settingsUrl}/summary`, { params: paramsToSend }).toPromise();
    } catch (error) {
      console.error("Error fetching summary settings:", error);
      throw error;
    }
  }

  async connectSmtp(smtpModel: SmtpConnectModel) {
    try {
      await this.http.post<void>(`${this.settingsUrl}/smtp/connect`, smtpModel).toPromise();
    }catch(error) {
      console.error("Error connecting to SMTP:", error);
      throw error;
    }
  }

  async disconnectSmtp(clientFilterModel: ClientFilterModel) {
    try {
      await this.http.put<void>(`${this.settingsUrl}/smtp/disconnect`, clientFilterModel).toPromise();
    } catch (error) {
      console.error("Error disconnecting from SMTP:", error);
      throw error;
    }
  }

  async getCurrentSmtpParameters(params: any) {
    try {
      return await this.http.get<SmtpParametersDto>(`${this.settingsUrl}/smtp/parameters`, { params: params }).toPromise();
    }
    catch (error) {
      console.error("Error fetching smtp settings:", error);
      throw error;
    }
  }

  async getFlowStateMappings(): Promise<FlowstateMappingDto> {
    const queryScope = this.queryStateService.addClientParameters({});
    return this.http.get<FlowstateMappingDto>(`${this.settingsUrl}/flow-state-mapping`, {params: queryScope as any}).toPromise();
  }

  async updateFlowStateMappings(flowStateMapping: any): Promise<void> {
    const dto: FlowstateMappingDto = {
      clientId: this.queryStateService.getClientId(),
      flowMappings: flowStateMapping,
    }
    return this.http.put<void>(`${this.settingsUrl}/flow-state-mapping`, dto).toPromise();
  }
}
