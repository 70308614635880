import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Language} from 'src/app/model';
import { FormlyService } from "../../ui-form/formly.service";

@Injectable()
export class PreferenceService {
  constructor(private translateService: TranslateService, private formlyService: FormlyService) {
  }

  setPreferredLanguage(lang: Language): Observable<any> {
    let languageCode;  // Only FR and NL supported for now
    switch (lang) {
      case Language.French:
        languageCode = 'fr-be';
        break;
      case Language.English:
        languageCode = 'en-be';
        break;
      default: // Use NL for other languages
        languageCode = 'nl-be';
        break;
    }

    return this.translateService
      .use(languageCode)
      .pipe(tap(() => this.formlyService.setLanguage()));
  }

  getCurrentLanguage(): Language {
    const currentLang = this.translateService.currentLang;
    switch (currentLang) {
      case 'fr-be':
        return Language.French;
      case 'en-be':
        return Language.English;
      default:
        return Language.Dutch;
    }
  }
}
