import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { environment } from 'src/environments/environment';

export class OpenIdConnectFactory {
  public static createUserManager(): UserManager {
    // Enable logging for debugging
    Log.logger = console;
    Log.level = Log.INFO;

    const config: any = {
      authority: environment.authEndpoint,
      client_id: 'spaclient',
      redirect_uri: environment.appUri,
      post_logout_redirect_uri: environment.appUri,
      response_type: 'id_token token',
      scope: 'openid profile email satocaapi.access',
      silent_redirect_uri: environment.appUri + 'auth.html',
      silentRequestTimeout: 3000,
      automaticSilentRenew: true,
      loadUserInfo: true,
      clockSkew: 60, // 1 minute clock skew is allowed
      filterProtocolClaims: true,
      stateStore: new WebStorageStateStore({ store: window.localStorage }),
      userStore: new WebStorageStateStore({ store: window.localStorage })
    };

    const manager = new UserManager(config);
    
    // Log errors for debugging
    manager.events.addSilentRenewError((error) => {
      console.error('Silent renew error:', error);
    });
    
    manager.events.addAccessTokenExpired(() => {
      console.log('Access token expired');
    });

    return manager;
  }
}
