import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // For non relative path urls or asset calls
    // we assume it's not an api call and we don't append the base api endpoint
    if (req.url.startsWith('http') || req.url.startsWith('/assets')) {
      return next.handle(req);
    }

    // Check if we have a valid token
    const token = this.authService.accessToken;
    if (!token && !req.url.includes('auth')) {
      console.warn('No access token available for API request:', req.url);
      return throwError(new Error('No access token available'));
    }

    // Clone the request with the API endpoint and auth headers
    const apiReq = req.clone({
      url: `${environment.apiEndpoint}/${req.url}`,
      headers: this.addExtraHeaders(req.headers, token)
    });

    return next.handle(apiReq).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          console.log('Response status:', evt.status, 'URL:', apiReq.url);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.error('401 Unauthorized error:', {
            url: apiReq.url,
            headers: apiReq.headers.keys(),
            token: !!token,
            error
          });
          // Force re-authentication
          this.authService.login();
        }
        return throwError(error);
      })
    );
  }

  private addExtraHeaders(headers: HttpHeaders, token: string | null): HttpHeaders {
    let newHeaders = headers || new HttpHeaders();
    
    // Add auth header if we have a token
    if (token) {
      newHeaders = newHeaders.set('Authorization', `Bearer ${token}`);
    }
    
    // Add common headers
    newHeaders = newHeaders
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');
      
    return newHeaders;
  }
}
